<template>
  <div id="mediaView" class="viewComp">
    <div class="main">
      <h1>Медиа отдел</h1>
      <div class="texts">
        <p>
          Открываем новые возможности для наших клиентов – через нестандартный
          подход к решению задач по их брифам, работая для них консультантами и
          используя инсайты для обеспечения роста на протяжении всех этапов
          общения с потребителем (с первого контакта с брендом, до его покупки и
          потребления).
        </p>
        <p>
          Медиа отдел фокусируется на достижении максимального охвата и
          разработке решений по трансформации медиастратегий, учитывая изменения
          в бизнесе клиентов и его конкурентов. Специалисты медиа отдела —
          эксперты по работе с аналитикой и данными, для разработки
          стратегических решений используют инсайты о барьерах и мотивах
          потребителей, их поведению, а так же данные по каждому сегменту
          бизнеса клиентов. Помимо аналитики и стратегических решений, медиа
          менеджеры является экспертом в размещении и закупке рекламы во всех
          каналах коммуникаций.Работая вместе с другими отделами на этапе
          планирования будущих размещений, мы всегда ищем новый путь для
          снижения затрат и для дальнейшего повышение эффективности рекламы.
        </p>
        <p>
          Философия отдела заключается в том, что фокусирование на людях –
          сотрудниках, клиентах, потребителях – приводит к наилучшим результатам
          в бизнесе.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Media",
};
</script>
